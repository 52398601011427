import React from 'react'
import styled from 'styled-components'
import Navbar from '../components/Navbar'
import Banner from '../components/Banner'
import Services from '../components/Services'
import Company from '../components/Company'
import Work from '../components/Work'
import Technologies from '../components/Technologies'
import Reviews from '../components/Reviews'
import Contact from '../components/Contact'
import Background from '../components/Background'
import BackgroundFooter from '../components/BackgroundFooter'
import Spacer from '../components/Spacer'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-scroll'


const Container = styled.div`
    width: 1280px;
    height: 100%;

`
const Uparrow = styled.div`
    position: fixed;
    font-size: 50px;
    cursor: pointer;
   
    
    color: black;
    bottom: -9px;
    right: 20px;
`


const Home = () => {
    return (
        <Container>
            <Background />
            <Navbar />
            <Banner />
            <Spacer />
            <Services />
            <Spacer />
            <Company />
            <Spacer />
            <Work />
            <Spacer />
            <Technologies />
            {/* <Spacer /> */}
            {/*  <Reviews />
            <Spacer /> */}
            <Contact />
            <BackgroundFooter />
            <Uparrow><Link to="/" smooth={true} duration={800} delay={50}><KeyboardArrowUpIcon sx={{ fontSize: "inherit" }} /></Link></Uparrow>

        </Container>
    )
}

export default Home