import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

`
const Title = styled.div`
    font-size: 13px;
    font-weight: 600;
    color: #0272b1;
    letter-spacing: 2px;
    

 
`
const SubTitle = styled.div`
font-size: 35px;
font-weight: 800;


`
const Desc = styled.div`
width: 1000px;
text-align: center;
font-size: 15px;
margin-top: 5px;
letter-spacing: 2px;
color: #676a6e;
line-height: 25px;

`

const NavigationText = ({ title, subtitle, desc }) => {
    return (
        <Container>
            <Title>{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
            <Desc>{desc}</Desc>
        </Container>
    )
}

export default NavigationText