import React from 'react'
import styled from 'styled-components'
import NavigationText from './NavigationText'
import ProductList from './ProductList'

const Container = styled.div`
    width: 100%;
    height: 920px;
`

const Work = () => {
    return (
        <Container id="work">
            <NavigationText title={"OUR WORK"} subtitle={"What We Build"} desc={"We Create Memorable Digital Experiences — From Product To Process."} />
            <ProductList />
        </Container>
    )
}

export default Work