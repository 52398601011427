import React, { useState } from 'react'
import styled from 'styled-components'
import Product from "./Product";

import { products } from "../data";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  `

const Texts = styled.div`
  width: 65%;
`
const Title = styled.h1`
  font-size: 50px;
  font-weight: 600;`
const Desc = styled.p`
  margin: 20px 0px;
`
const List = styled.div`
 width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;`

const ProductList = () => {
  const [data, setdata] = useState(products)
  return (

    <Container>
      {/*  <Texts>
        <Title>Create & inspire. It's Lama</Title>
        <Desc> Lama is a creative portfolio that your work has been waiting for.
          Beautiful homes, stunning portfolio styles & a whole lot more awaits
          inside.
        </Desc>

      </Texts> */}
      <List>
        {data.map((item) => (
          <Product key={item.id} img={item.img} link={item.link} />
        ))}
      </List>
    </Container>

  )
}

export default ProductList