import React, { useState } from 'react'
import styled from 'styled-components'
import reviewdata from '../reviewData'
import NavigationText from './NavigationText'
import ReviewCard from './ReviewCard'

const Container = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -90px;
`

const Line = styled.line`
    margin-top: -15px;
    margin-bottom: 25px;
    width: 100px;
    border: 1px solid black;
    border-width: 2px;
    border-color: #49A6E9;

`


const Reviews = () => {
    const [index, setIndex] = useState(0)
    const [data, setData] = useState(reviewdata)
    return (
        <Container>
            <NavigationText title={"OUR REVIEWS"} subtitle={"Hear from Our Clients"} desc={"We Create Memorable Digital Experiences— From Product To Process. Lama is a creative portfolio that your work has been waiting for.Beautiful homes, stunning portfolio styles & a whole lot more awaits inside. "} />
            <Line></Line>
            <ReviewCard {...data[index]} index={index} setIndex={setIndex} data={data} />
        </Container>
    )
}

export default Reviews