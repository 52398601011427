import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import ui from '../img/ui.png'
import emailjs from 'emailjs-com'

const Container = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    top: 60px;
`


const Card = styled.div`
    width: 1000px;
    height: 500px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;


`
const Img = styled.img`
    width: 90px;
    height: 60px;
    display: flex;
    padding-left: 50px;
    padding-top: 70px;
   
`
const TextBox = styled.div`
    width: 400px;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    font-size: 40px;
    display: flex;
    flex-direction: column;
 
    align-items: baseline ;
    justify-content: baseline;

    
`
const Text = styled.div`
    width: 300px;
        padding-left: 60px;


    
`
const FormWrapper = styled.div`
    width: 600px;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;


`

const Form = styled.form`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
gap:20px;    
`
const Try = styled.div`
    width: 450px;
    display: flex;
    gap: 15px;
`
const InputBox = styled.div`
    display: flex;
    flex-direction: column;
`
const InputText = styled.div`
    display: flex;
    gap: 15px;
    color: #676a6e;
`


const Input = styled.input`

width: ${props => props.messagebox ? "450px" : "200px"};
height: ${props => props.messagebox ? "80px" : "40px"};
border: 1px solid #dce1e9 ;
::placeholder{ color: gray; align-items:center;};
padding: 0 20px;
border-radius: 5px;
&:focus {
    outline: none;
    border: 1px solid #0272b1;
  }

`
const OutlineButton = styled.button`
height: 40px;
width: 180px;
margin-top:20px;
background-color: #2363bb;
font-weight: 300;
letter-spacing: 2px;
font-size: 14px;
color: white;
border: 1px solid white;
border-radius: 5px;
//box-shadow: 1.1px 2.2px 2.2px hsl(0deg 0% 0% / 0.47);
&:hover{ opacity:0.8; }
cursor: pointer;
`

const Contact = () => {
    const formRef = useRef()
    const [done, setDone] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_jv0n4s6",
                "template_bulo4cp",
                formRef.current,
                "_RA2f0miMfFPYZMZY"
            ).then(
                (result) => {
                    console.log(result.text);
                    setDone(true)
                },
                (error) => {
                    console.log(error.text);
                }
            )
        //reset inputs
        formRef.current.reset();

    };
    return (
        <Container id="contact">
            <Card>
                <TextBox>
                    <Img src={ui} />
                    <Text>
                        Have an Idea in Mind? Let's Built It Together.

                    </Text>
                </TextBox>
                <FormWrapper>
                    {/* <CardWrapper> */}
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <Try>
                            <InputBox>
                                <InputText>First name*</InputText>
                                <Input required="true" type="text" name="user_firstname" />
                            </InputBox>
                            <InputBox>
                                <InputText>Last name</InputText>
                                <Input required="true" type="text" name="user_lastname" />
                            </InputBox>
                        </Try>
                        <Try>
                            <InputBox>
                                <InputText>Email*</InputText>
                                <Input required="true" type="text" name="user_email" />
                            </InputBox>
                            <InputBox>
                                <InputText>Phone number</InputText>
                                <Input type="text" name="user_phone" />
                            </InputBox>
                        </Try>

                        <Try>
                            <InputBox>
                                <InputText>How can we help you?*</InputText>
                                <Input required="true" messagebox type="text" name="message" />
                            </InputBox>

                        </Try>
                        <OutlineButton >Submit</OutlineButton>
                        {done && "Thank You - We will get back soon :)"}


                    </Form>

                    {/* </CardWrapper> */}
                </FormWrapper>
            </Card>
        </Container>
    )
}

export default Contact