import React from 'react'
import styled from 'styled-components'
import web from '../img/web.png'
import app from '../img/app.png'
import ui from '../img/ui.png'
import gif from '../img/gif.gif'
import icon1 from '../img/icon1.gif'
import icon2 from '../img/icon2.gif'
import icon3 from '../img/icon3.gif'
import icon4 from '../img/icon4.gif'
import icon5 from '../img/icon5.gif'
import icon6 from '../img/icon6.gif'



import NavigationText from './NavigationText'

const Container = styled.div`
    height: 380px;
    width: 100%;
    display: flex;
    flex-direction: column;
   
  `
const CardWrapper = styled.div`
    height: 228px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `

const Card = styled.div`
    width: 195px;
    height: 180px;
    border-radius: 15px;
    box-shadow: 4.2px 8.3px 8.3px hsl(0deg 0% 0% / 0.17);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    //background-color: white;
    &:hover{ opacity:0.8; border: 1px solid #0272b1; color:#0272b1;  }
    cursor: pointer;

`
const Img = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;
`
const Heading = styled.div`
text-align: center;
width: 166px;
font-weight: 800;
font-size: 15px;
letter-spacing: 2px;

    
`


const Services = () => {
    return (
        <Container id="services">
            <NavigationText title={"OUR SERVICES"} subtitle={"What We Offer"} desc={"We partner with ambitious companies, brands, and founders to build premium and functional mobile and web apps with rich user interfaces using the best tech stack. We help startups with prototyping, designing, and converting an idea into a successful business."} />
            <CardWrapper>
                <Card>
                    <Img src={icon3} />
                    <Heading>Digital Marketing</Heading>
                </Card>
                <Card>
                    <Img src={icon4} />
                    <Heading>Product UI/ UX Design</Heading>
                </Card>
                <Card>
                    <Img src={icon1} />
                    <Heading>Web App Developement</Heading>
                </Card>
                <Card>
                    <Img src={icon2} />
                    <Heading>Mobile App Developement</Heading>

                </Card>
                <Card>
                    <Img src={icon5} />
                    <Heading>eCommerce Developement</Heading>
                </Card>
                <Card>
                    <Img src={icon6} />
                    <Heading>Custom Software Solutions</Heading>
                </Card>
            </CardWrapper>

        </Container>
    )
}

export default Services