import styled from "styled-components";
import Home from "./pages/Home";

function App() {
  const MainContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  `

  return (
    <MainContainer>
      <Home/>

    </MainContainer> 
  );
}

export default App;
