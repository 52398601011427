import React from 'react'
import styled from 'styled-components'
import social from '../img/social.png'
import { Link } from 'react-scroll'


const Dark = styled.div`
    width: 100%;
    height: 450px;
        position: absolute;
    z-index: -1;
    left: 0px;
    justify-content    :center ;
    background-color: #DCE1E9;
    display: flex;
    justify-content: center;
    align-items: center;
 //   opacity: 0.1;
    
`

const Footer = styled.div`
    width: 1280px;
    height: 100%;
    display: flex;
    flex-direction: column;

`
const Top = styled.div`
    height: 370px;
    display: flex;
    justify-content: space-between;
    padding-top: 100px;


`
const TopText1 = styled.div`
flex: 2;
display: flex;
flex-direction: column;

`
const TopText1Heading = styled.div`
font-weight: 600;
font-size: 26px;
margin-bottom: 10px;


`
const TopText1Content = styled.div`
letter-spacing: 2px;
line-height: 30px;
color:#676a6e;



`
const TopText2 = styled.div`
flex: 1;
display: flex;
flex-direction: column;
padding-left: 200px;
`
const TopText2Heading = styled.div`
font-weight: 600;
margin-bottom: 10px;


`
const TopText2Content = styled.div`
letter-spacing: 2px;
padding-bottom: 10px;
line-height: 30px;
color:#676a6e;
cursor: pointer;

`
const TopText3 = styled.div`
flex: 1;
display: flex;
flex-direction: column;
padding-left: 200px;
`
const TopText3Heading = styled.div`
font-weight: 600;
margin-bottom: 10px;


`
const TopText3Content = styled.div`
letter-spacing: 2px;
padding-bottom: 10px;
line-height: 30px;
color:#676a6e;
cursor: pointer;

`
const Bottom = styled.div`
width: 1280px;
    height: 80px;
    border-top: 1px solid #c5cad0;
    display: flex;
    align-items: center;
   

`
const BottomText = styled.div`
width: 100%;
    color: #676a6e;
    display: flex;
    justify-content: space-between;


`
const BottomText1 = styled.div`
    color: #676a6e;
    font-weight: 600;

`
const BottomText2 = styled.div`
    color: #676a6e;
    cursor: pointer;

`

const OutlineButton = styled.button`
height: 40px;
width: 180px;
background-color: white;
font-weight: 300;
letter-spacing: 2px;
font-size: 14px;
color: black;
border: 1px solid white;
border-radius: 5px;
box-shadow: 1.1px 2.2px 2.2px hsl(0deg 0% 0% / 0.47);
&:hover{ opacity:0.8; }
cursor: pointer;
`

const Background = () => {
    return (
        <>
            <Dark>
                <Footer>
                    <Top>
                        <TopText1>
                            <TopText1Heading>
                                FINSTARK
                            </TopText1Heading>
                            <TopText1Content>
                                Finstark is a certified web and mobile app development company that provides excellent business solutions in eCommerce, Product Design, Software development, and Digital Marketing to SMEs, Startups, and Large Enterprises.

                            </TopText1Content>

                        </TopText1>
                        <TopText2>
                            <TopText2Heading>
                                Quick Links
                            </TopText2Heading>
                            <TopText2Content><Link to="services" smooth={true} offset={-80} duration={800} delay={50}>Services</Link></TopText2Content>
                            <TopText2Content><Link to="work" smooth={true} offset={-20} duration={1000} delay={50}>Work</Link></TopText2Content>
                            <TopText2Content><Link to="technologies" smooth={true} offset={-80} duration={800} delay={50}>Technologies</Link></TopText2Content>
                            <TopText2Content><Link to="company" smooth={true} offset={-80} duration={800} delay={50}>About</Link></TopText2Content>
                            <TopText2Content><Link to="#" smooth={true} offset={-80} duration={800} delay={50}>Privacy Policy</Link></TopText2Content>

                        </TopText2>
                        <TopText3>
                            <TopText3Heading>
                                Contact Us
                            </TopText3Heading>
                            <TopText3Content>finstark.inc@gmail.com</TopText3Content>

                            <img style={{ marginTop: 20, marginLeft: -15, marginBottom: 10 }} src={social} />
                            <OutlineButton>{"We are Hiring ->"}</OutlineButton>

                        </TopText3>
                    </Top>
                    <Bottom>

                        <BottomText>
                            <BottomText1>@2022 Finstark Private Limited.</BottomText1>
                            <BottomText2><Link to="#" smooth={true} offset={-80} duration={800} delay={50}>Privacy Policy</Link> | All rights reserved.</BottomText2>

                        </BottomText>
                    </Bottom>
                </Footer>
            </Dark>
        </>
    )
}

export default Background