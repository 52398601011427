import Portfolio1 from '../src/img/portfolio1.png'
import Portfolio2 from '../src/img/portfolio2.png'
import Portfolio3 from '../src/img/portfolio3.png'
import Portfolio4 from '../src/img/portfolio4.png'
import Portfolio5 from '../src/img/portfolio5.png'
import Portfolio6 from '../src/img/portfolio6.png'

export const products = [
    {
      id: 1,
      img: `${Portfolio1}`,
      link: "https://saltandspring.com/"
     
    },
    {
      id: 2,
      img: `${Portfolio2}`,
      link: "https://www.bohemiaprintshop.com/",
    },
    {
      id: 3,
      img: `${Portfolio3}`,
     // link: "",
    },
    {
      id: 4,
      img: `${Portfolio4}`,
      //link: "",
    },
    {
      id: 5,
      img: `${Portfolio5}`,
    //  link: "",
    },
    {
      id: 6,
      img: `${Portfolio6}`,
     // link: "",
    },
  ];