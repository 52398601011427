import React from 'react'
import styled from 'styled-components'
import NavigationText from './NavigationText'
import js from '../img/javascript.png'
import nodejs from '../img/nodejs.png'
import firebase from '../img/firebase.png'
import mern from '../img/mern.png'
import mongodb from '../img/mongodb.png'
import react from '../img/react.png'
import reactnative from '../img/reactnative.png'
import shopify from '../img/shopify.png'

const Container = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const CardWrapper = styled.div`
    height: 300px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
  `

const Card = styled.div`
    width: 220px;
    height: 120px;
    border-radius: 15px;
    box-shadow: 4.2px 8.3px 8.3px hsl(0deg 0% 0% / 0.17);
    display: flex;
    justify-content: center;
    align-items: center;
   background-color: white;


`
const Img = styled.img`
    width: 80%;
    object-fit: cover;
`

const Technologies = () => {
    return (
        <Container id="technologies">
            <NavigationText title={"OUR TECH STACK"} subtitle={"State of the art tech stack"} desc={"We pride ourselves in using the latest technologies to create aesthetic, seamless, and efficient applications for our clients, which results in increased customer satisfaction and ROI."} />
            <CardWrapper>
                <Card>
                    <Img src={shopify} />
                </Card>
                <Card>
                    <Img src={react} />
                </Card><Card>
                    <Img src={mern} />
                </Card><Card>
                    <Img src={reactnative} />
                </Card><Card>
                    <Img src={nodejs} />
                </Card><Card>
                    <Img src={js} />
                </Card><Card>
                    <Img src={mongodb} />
                </Card><Card>
                    <Img src={firebase} />
                </Card>
            </CardWrapper>
        </Container>
    )
}

export default Technologies