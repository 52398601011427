import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
 width: 400px;
  height: 330px;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  `
const BrowserCircle = styled.div`
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
`
const Circle = styled.div`
width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: white;
`
const Img = styled.img`
width: 100%;
  transition: all 10s ease;
  &:hover{transform: translateY(-75%);}

`
const Product = ({ img, link }) => {
  return (
    <Container>
      <BrowserCircle>
        <Circle />
        <Circle />
        <Circle />
      </BrowserCircle>

      <a href={link} target="_blank" rel="noreferrer">
        <Img src={img} alt="" className="p-img" />
      </a>
    </Container>
  )
}

export default Product