import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    height: 80px;
    background-color: transparent;
`

const Spacer = () => {
    return (
        <Container>

        </Container>
    )
}

export default Spacer