import React from 'react'
import styled from 'styled-components'
import team from '../img/team.png'

const BackgroundImg = styled.img`
    width: 100%;
    height: 790px;
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 0px;
    object-fit: cover;
`
const Dark = styled.div`
 width: 100%;
    height: 790px;
        position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    background-color: black;
    opacity: 0.4;
    
`

const Background = () => {
    return (
        <>
            <Dark />
            <BackgroundImg src={team} />
        </>
    )
}

export default Background