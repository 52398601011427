import React from 'react'
import styled from 'styled-components'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const Container = styled.div`
    width: 800px;
    height: 300px;
    //box-shadow: 4.2px 8.3px 8.3px hsl(0deg 0% 0% / 0.37);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`
const Img = styled.img`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 100%;
`
const Name = styled.div`
    font-size: 20px;
    font-weight: 600;
`
const Desc = styled.div`
    padding: 0px 30px;
    text-align: center;
    letter-spacing: 2px;
`
const Arrow = styled.div`
width: 1100px;
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 220px;
    
 
`
const Btn = styled.div`
    
    cursor: pointer;
`
const Dot = styled.div`
    display: flex;
    gap: 5px;
    font-size: 15px;
`




const ReviewCard = ({ id, name, job, image, text, index, setIndex, data }) => {


    const handleClickM = () => {
        setIndex(index > 0 ? index - 1 : data.length - 1)
    }

    const handleClickP = () => {
        setIndex(index < data.length - 1 ? index + 1 : 0)
    }

    //End

    const handleClickR = () => {
        let random = Math.floor(Math.random() * data.length)
        setIndex(random)

    }
    return (
        <Container>
            <Img src={image} />
            <Desc>{text}</Desc>
            <Name>{name}</Name>




            <Arrow>
                <Btn onClick={handleClickM}>{"<"}</Btn>
                <Btn onClick={handleClickP}>{">"}</Btn>
            </Arrow>

        </Container>
    )
}

export default ReviewCard