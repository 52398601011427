import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import emailjs from 'emailjs-com'



const Container = styled.div`
    height: 720px;
    width: 100%;
    display: flex;
    justify-content: center;
  `
const Left = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    gap: 20px;

    `

const Title = styled.h1`
    font-size: 70px;
    display: flex;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 130px;
    color: white;
`

const ServicesAnimate = styled.div`
    height: 50px;
    padding-left: 10px;
    padding-right: 200px;
     overflow: hidden;

`
const ServicesAnimateWrapper = styled.div`
height: 100%;
  animation: move 10s ease-in-out infinite alternate;
  @keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

`

const ServicesAnimateItem = styled.div`
 height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: green;
  display: flex;
  align-items: center;
  
`

const Description = styled.h3`
 padding-left: 10px;
 padding-right: 180px;
 font-size: 25px;
 font-weight: 400;
 color: white;
 letter-spacing: 4px;
 
`




const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    margin-top: 70px;

`
const CardWrapper = styled.div`
    height: 521px;
    width: 412px;
    background-color: #e6e7e8E6;
    box-shadow: 4.2px 8.3px 8.3px hsl(0deg 0% 0% / 0.37);
    display: flex;
    flex-direction: column;
  //  justify-content: center;
    align-items: center;
    border-radius: 5px;
`
const Heading = styled.h2`
    width: 80%;
    text-align: center;
    color: #0272b1;
    font-weight: 600;
    letter-spacing: 2px;
    margin-top: 30px;
`

const Form = styled.form`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:20px;    
margin-top: 50px;

`

const Input = styled.input`

width: 300px;
font-weight:600;
height: ${props => props.messagebox ? "80px" : "40px"};
border: none;
::placeholder{ color: #676a6e; align-items:center; };
padding: 0 20px;
border-radius: 5px;
&:focus {
    outline: none;
    border: 1px solid #0272b1;
  }

`
const Button = styled.button`
height: 45px;
width: 240px;
margin-top: 10px;
//background-color: #fc0;
background-color:#0272b1;
font-weight: 500;
font-size: 14px;
color: #fff;
//color: #000;
border: none;
border-radius: 5px;
box-shadow: 1.1px 2.2px 2.2px hsl(0deg 0% 0% / 0.47);
&:hover{ opacity:0.8; }
cursor: pointer;

`
/* const OutlineButton = styled.button`
margin-top: 20px;
margin-left: 10px;
height: 45px;
width: 190px;
font-weight: 500;
letter-spacing: 2px;
font-size: 14px;
color: #0272b1;
//border: 1px solid #0272b1;
border-radius: 5px;
//box-shadow: 1.1px 2.2px 2.2px hsl(0deg 0% 0% / 0.47);
&:hover{ opacity:0.8; }
cursor: pointer;
` */

const OutlineButton = styled.button`
margin-top: 20px;
margin-left: 10px;
height: 45px;
width: 190px;
background-color: transparent;
font-weight: 600;
font-size: 14px;
letter-spacing: 2px;
//color: black;
color: #fff;
border: 1px solid #fff;
border-radius: 5px;
//box-shadow: 1.1px 2.2px 2.2px hsl(0deg 0% 0% / 0.47);
&:hover{ opacity:0.8;}
cursor: pointer;
`


const Banner = () => {
  const formRef = useRef()
  const [done, setDone] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_jv0n4s6",
        "template_bulo4cp",
        formRef.current,
        "_RA2f0miMfFPYZMZY"
      ).then(
        (result) => {
          console.log(result.text);
          setDone(true)
        },
        (error) => {
          console.log(error.text);
        }
      );
    //reset inputs
    formRef.current.reset();


  };
  return (
    <Container>
      <Left>

        <Title>Build Your Web & Mobile Apps Better, Faster & Cheaper.</Title>
        <Description>We Create Memorable Digital</Description>
        <Description>Experiences From Product To Process.</Description>
        {/*  <ServicesAnimate>
                    <ServicesAnimateWrapper>
                        <ServicesAnimateItem>Web Design</ServicesAnimateItem>
                        <ServicesAnimateItem>UI/UX Developer</ServicesAnimateItem>
                        <ServicesAnimateItem>Wordpress</ServicesAnimateItem>
                        <ServicesAnimateItem>Shopify</ServicesAnimateItem>
                        <ServicesAnimateItem>React</ServicesAnimateItem>
                    </ServicesAnimateWrapper>
                </ServicesAnimate> */}
        <OutlineButton>VIEW OUR WORK</OutlineButton>

      </Left>
      <Right>
        <CardWrapper>
          <Heading>Have A Project in Mind? Chat With Our Experts.</Heading>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input required="true" type="text" placeholder="Your Name" name="user_firstname" />
            <Input required="true" type="text" placeholder="Your work email" name="user_email" />
            <Input required="true" type="text" placeholder="Subject" name="user_subject" />
            <Input required="true" messagebox type="text" placeholder="What can we do for you?" name="message" />
            <Button>BOOK A FREE CONSULTATION</Button>
            {done && "Thank You - We will get back soon :)"}


          </Form>

        </CardWrapper>
      </Right>
    </Container>
  )
}

export default Banner