import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    height: 450px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
`
const Dark = styled.div`
 width: 100%;
    height: 500px;
        position: absolute;
    z-index: -1;
    left: 0;
    
    background-color: black;
    opacity: 0.015;

    
`
const Title = styled.div`
    color: orange;
    font-weight: 600;
    font-size: 18px;
`
const BoldText = styled.div`
    //font-size: 40px;
   // width: 90%;
    font-size: 33px;
    line-height: 55px;
    font-weight: 500;
    letter-spacing: 2px;
   // margin-bottom: 20px;
       color: #424446;

`
const Text = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 2px;
    color: #676a6e;
`
const Highlight = styled.line`
    border-bottom: 3px solid orange;
    
   
    
`


const Company = () => {
    return (
        <Container id="company">
            <Dark />
            <Title>Who are we ___</Title>
            <BoldText>
                Finstark is a <Highlight>Certified Web and Mobile App development</Highlight> company that provides excellent business solutions in eCommerce, Product Design, Software development, and Digital Marketing to SMEs, Startups, and Large Enterprises.
            </BoldText>
            <Text>We pride ourselves in adopting the latest tech stack including MERN Stack, React JS, Redux JS, Node JS, Mongo DB, Firebase, MUI, ExpressJS, and other web technologies to create aesthetic, seamless, and efficient applications for our clients, which results in increased customer satisfaction and ROI.

            </Text>
        </Container>
    )
}

export default Company