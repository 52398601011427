import React from 'react'
import styled from 'styled-components'
import finstarklogo from '../img/finstarklogo.png'
import { Link } from 'react-scroll'

const Container = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    //box-shadow: 0px 0.2px 0px hsl(0deg 0% 0% / 0.47);
`
const RectangleBack = styled.div`
    height: 80px;
    width: 100%;
    //top: 5px;
    left: 0;
    right: 0;
    margin: auto;
   // border-radius: 10px;
    background-color: white;
    position: absolute;
    z-index: -1;
   // opacity: 0.8;
`
const Left = styled.div`
flex: 1;
height: 100%;
display: flex;
align-items: center;
`
const Right = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: end;
gap: 40px;
height: 100%; 
`

const Logo = styled.img`
height: 40px;
width: 130px;
object-fit: cover;
`
const MenuItem = styled.div`
cursor: pointer;
font-size: 14px;
letter-spacing: 2px;
`

const OsutlineButton = styled.button`
height: 40px;
width: 120px;
background-color: #F7B500;
font-weight: 400;
color: black;
border: none;
border-radius: 5px;
box-shadow: 1.1px 2.2px 2.2px hsl(0deg 0% 0% / 0.47);
&:hover{ opacity:0.8; }
cursor: pointer;
font-size: 14px;
letter-spacing: 2px;
`
const OutlineButton = styled.button`
height: 40px;
width: 120px;
background-color: white;
font-weight: 600;
font-size: 14px;
letter-spacing: 2px;
//color: black;
color: #0272b1;
border: 1px solid #0272b1;
border-radius: 5px;
//box-shadow: 1.1px 2.2px 2.2px hsl(0deg 0% 0% / 0.47);
&:hover{ opacity:0.8;  background-color:#0272b1; color: white; border: 1px solid white;}
cursor: pointer;
`



const Navbar = () => {
    return (
        <Container id="/">
            <RectangleBack />
            <Left>
                <Link to="/" spy={true} smooth={true} offset={-80} duration={500}><Logo src={finstarklogo} /></Link>

            </Left>
            <Right>

                <MenuItem><Link to="services" smooth={true} offset={-80} duration={800} delay={50}>SERVICES</Link></MenuItem>
                <MenuItem><Link to="company" smooth={true} offset={-80} duration={800} delay={50}>COMPANY</Link></MenuItem>
                <MenuItem><Link to="work" smooth={true} offset={-20} duration={1000} delay={50}>WORK</Link></MenuItem>
                <MenuItem><Link to="technologies" smooth={true} offset={-80} duration={800} delay={50}>TECHNOLOGIES</Link></MenuItem>
                <Link to="contact" smooth={true} offset={-80} duration={800} delay={50}><OutlineButton>CONTACT</OutlineButton></Link>
            </Right>
        </Container>
    )
}
export default Navbar